import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { CompletedQuestionSt, QuestionSt, QuizResultSt, QuizScreen, QuizSt, SendAnswerSt } from '@shared/models';

export const load = createAction('[Learning Quiz] Load', props<{ id: string }>());

export const loadSuccess = createAction('[Learning Quiz] Load success', props<{ quiz: QuizSt }>());

export const loadFail = createAction(
  '[Learning Quiz] Load fail',
  props<{ error: HttpErrorResponse }>()
);

export const setScreen = createAction(
  '[Learning Quiz] Set screen',
  props<{ screen: QuizScreen }>()
);

export const setAttemptId = createAction(
  '[Learning Quiz] Set attemptId',
  props<{ attemptId: string }>()
);

export const start = createAction('[Learning Quiz] Start');

export const startSuccess = createAction('[Learning Quiz] Start success');

export const startFail = createAction(
  '[Learning Quiz] Start fail',
  props<{ error: HttpErrorResponse }>()
);

export const continueQuiz = createAction('[Learning Quiz] Continue quiz');

export const continueQuizSuccess = createAction('[Learning Quiz] Continue quiz success');

export const setCompletedQuestions = createAction(
  '[Learning Quiz] Set completed questions',
  props<{
    completedQuestions: CompletedQuestionSt[];
  }>()
);

export const continueQuizFail = createAction(
  '[Learning Quiz] Continue quiz fail',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const setActiveQuestion = createAction(
  '[Learning Quiz] Set active question',
  props<{ question: QuestionSt }>()
);

export const sendAnswer = createAction('[Learning Quiz] Send answer', props<SendAnswerSt>());

export const sendAnswerSuccess = createAction('[Learning Quiz] Send answer success');

export const sendAnswerFail = createAction(
  '[Learning Quiz] Send answer fail',
  props<{ error: HttpErrorResponse }>()
);

export const saveCompletedQuestion = createAction(
  '[Learning Quiz] Save completed question',
  props<{ question: CompletedQuestionSt }>()
);

export const prevQuestion = createAction('[Learning Quiz] Prev question');

export const prevQuestionSuccess = createAction('[Learning Quiz] Prev question success');

export const prevQuestionFail = createAction('[Learning Quiz] Prev question fail');

export const nextQuestion = createAction('[Learning Quiz] Next question');

export const nextQuestionSuccess = createAction('[Learning Quiz] Next question success');

export const nextQuestionFail = createAction(
  '[Learning Quiz] Next question fail',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const result = createAction('[Learning Quiz] Result');

export const resultSuccess = createAction(
  '[Learning Quiz] Result success',
  props<{ result: QuizResultSt }>()
);

export const resultFail = createAction(
  '[Learning Quiz] Result fail',
  props<{ error: HttpErrorResponse }>()
);

export const setInitialState = createAction('[Learning Quiz] Set initial state');
